export const TOKEN_KEY = "@GOSC_ADMIN";
export const USER_ID = "@GOSC_USER";

export const isAuthenticated = () => sessionStorage.getItem(TOKEN_KEY) !== null;
export const getToken = () => sessionStorage.getItem(TOKEN_KEY);
export const login = token => sessionStorage.setItem(TOKEN_KEY, token);
export const logout = () => sessionStorage.removeItem(TOKEN_KEY);


export const getUserId = () => sessionStorage.getItem(USER_ID);
export const setUserId = user_id => sessionStorage.setItem(USER_ID, user_id);
export const removeUserId = () => sessionStorage.removeItem(USER_ID);