import React from "react";
import { Form, Button, Container, Card } from 'react-bootstrap'
import { connect } from 'react-redux';
import {login as loginUtil, setUserId} from '../../services/auth'
import NavComponent from '../../components/NavComponent/NavComponent'
import {login} from '../../api/login'
import './login.style.css'

const logo = require('../../assets/images/logo_gosc.png');

class LoginContainer extends React.Component{
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      senha: ''
    };

    this.onInputChange = this.onInputChange.bind(this);
    this.onLogin = this.onLogin.bind(this);
  }

  onInputChange(e) {
    const inputName = e.target.name;
    const inputValue = e.target.value;
    this.setState({ [inputName]: inputValue });
  };

  async onLogin(e){
    e.preventDefault();
    const {email, senha} = this.state;
    try {
      const response = await login(email, senha);

      if (response.data.success) {
        loginUtil(response.data.token);
        setUserId(response.data.user_id);
        this.props.history.push({
          pathname: '/home'
        });
      }
    } catch (e) {
      console.log(e)
    }
  }

  render() {
    const { email, senha } = this.state;
    return (
      <div>
        <NavComponent />
        <Container className='container-login'>
          <Card className='shadow-lg p-3 mb-5 bg-white rounded' style={{
            width: '27rem'
          }}>
            <Card.Img variant="top" src={logo} style={{
              width: 200,
              alignSelf: 'center',
              margin: 30
            }} />
            <Card.Body>
              <Form>
                <Form.Group controlId="formBasicEmail">
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    type="email"
                    name={'email'}
                    value={email}
                    onChange={this.onInputChange}
                    placeholder="exemplo@exemplo.com.br" />
                </Form.Group>
                <Form.Group controlId="formBasicPassword">
                  <Form.Label>Senha</Form.Label>
                  <Form.Control
                    type="password"
                    name={'senha'}
                    value={senha}
                    onChange={this.onInputChange}
                    placeholder="Senha" />
                </Form.Group>
                <Button
                  className='button-color'
                  type="submit"
                  onClick={this.onLogin}
                  style={{ width: '100%' }} >
                  Acessar
                </Button>
              </Form>
            </Card.Body>
          </Card>
        </Container>
      </div>
    )
  }
}

const mapStateToProps = (state, props) => ({});
const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(LoginContainer);