import { combineReducers, createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { routerReducer } from 'react-router-redux';

import reducers from './reducers';
import rootSaga from './sagas';

// Initialize the Redux set up
const composeEnhancers =
  (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
      trace: true,
      traceLimit: 1000
    })) ||
  compose;
const sagaMiddleware = createSagaMiddleware();

export const store = createStore(
  combineReducers({ ...reducers, routing: routerReducer }),
  composeEnhancers(applyMiddleware(sagaMiddleware))
);

// Run the saga now
sagaMiddleware.run(rootSaga);