import React, {Component} from "react"
import {Card, Col, Row} from "react-bootstrap";

import './MainRow.style.css'

export default class MainRow extends Component{
  render () {
    const {
      title,
      numDemandas,
      numAbertas,
      numAceitas,
      numArquivadas,
      color
    } = this.props;

    const classColor = `'text-center text-demands demand-title ${color}`;

    return (
      <Row className='root'>
        <Col className='col-12' >
          <Card>
            <Card.Body className='card-body-layout'>
              <Row>
                <Col className='demand-type col-4'>
                  <h6 className={classColor}>{title}</h6>
                </Col>
                <Col>
                  <p className='text-center text-demands numbers-title'>Total registradas</p>
                  <p className='text-center text-demands numbers-style'>{numDemandas}</p>
                </Col>
                <Col>
                  <p className='text-center text-demands numbers-title'>Total abertas</p>
                  <p className='text-center text-demands numbers-style'>{numAbertas ? numAbertas : 0}</p>
                </Col>
                <Col>
                  <p className='text-center text-demands numbers-title'>Total aceitas</p>
                  <p className='text-center text-demands numbers-style'>{numAceitas ? numAceitas : 0}</p>
                </Col>
                <Col>
                  <p className='text-center text-demands numbers-title'>Total arquivadas</p>
                  <p className='text-center text-demands numbers-style'>{numArquivadas}</p>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    )
  }
}