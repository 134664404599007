import React, {Component} from "react"
import { Container, Card, Row, Col } from 'react-bootstrap'
import { connect } from 'react-redux'
import { actions as demandActions } from '../../reducers/demands'
import * as selectorsDemands from '../../selectors/demands'
import _ from 'lodash'
import MainRow from '../../components/MainRow/MainRow'
import NavComponent from '../../components/NavComponent/NavComponent'
import PieGraphComponent from '../../components/PieGraph/PieGraphComponent'
import {classificationTypeToString} from '../../shared/utils'

import './home.style.css'

class HomeContainer extends React.Component {
  constructor(props) {
    super(props);
    this.getData = null;

    this.renderRow = this.renderRow.bind(this);
    this.renderPieGraph = this.renderPieGraph.bind(this);
  }
  componentDidMount = () => {
    const {
      getAllPublicDemands,
      getAllPublicDemandsFiled,
      getAllPrivateDemands,
      getAllPrivateDemandsFiled
    } = this.props;

    getAllPublicDemands();
    getAllPublicDemandsFiled();
    getAllPrivateDemands();
    getAllPrivateDemandsFiled();
  };

  componentWillUnmount() {
    clearInterval(this.getData)
  }

  renderRow = (demands, filed, color, title) => {
    const status =
      _.chain(demands)
      .groupBy('status')
      .map((items, status) => {
        return { status: status, count: items.length };
      })
      .value();

    const open =
      _.chain(status)
      .find(o => {
        return o.status === 'Aberta'
      })
      .get('count')
      .value();

    const accept =
      _.chain(status)
      .find(o => {
        return o.status === 'Aceita'
      })
      .get('count')
      .value();

    const total = demands.length + filed.length;

    return (
      <MainRow
        color={color}
        title={title}
        numDemandas={total}
        numAbertas={open}
        numAceitas={accept}
        numArquivadas={filed.length}
      />
    )
  };

  renderPieGraph = (demands, filed, filter, color, title) => {
    const all = _.union(demands, filed);
    const data =
      _.chain(all)
      .groupBy(filter)
      .map((items, filter) => {
        return { filter: classificationTypeToString(filter), count: items.length };
      })
      .value();

    return (
      <PieGraphComponent
        title={title}
        labels={data.map(({filter}) => filter)}
        dataSet={data.map(({count}) => count)}
        colors={color}
      />
    )
  };

  render = () => {
    const {
      public_demands,
      public_demands_filed,
      private_demands,
      private_demands_filed
    } = this.props;

    return (
      <div>
        <NavComponent/>
        <Container fluid>
          {this.renderRow(public_demands, public_demands_filed, 'public', 'Demandas Públicas')}
          <Row>
            <Col className='col-3 card-graphic' >
              <Card className='card-style'>
                <Card.Body className='card-body-layout'>
                  {this.renderPieGraph(public_demands, public_demands_filed, 'status', 'public', 'Demandas Públicas Estado')}
                </Card.Body>
              </Card>
            </Col>
            <Col className='col-3' >
              <Card className='card-style'>
                <Card.Body className='card-body-layout'>
                  {this.renderPieGraph(public_demands, public_demands_filed, 'classification', 'public', 'Demandas públicas classificação')}
                </Card.Body>
              </Card>
            </Col>
            <Col className='col-3' >
              <Card className='card-style'>
                <Card.Body className='card-body-layout'>
                  {this.renderPieGraph([], public_demands_filed, 'filed_by_description', 'public', 'Demandas públicas arquivadas')}
                </Card.Body>
              </Card>
            </Col>
            <Col className='col-3' >
              <Card className='card-style'>
                <Card.Body className='card-body-layout'>
                  {this.renderPieGraph([], public_demands_filed, 'reason', 'public', 'Demandas públicas por razão de arquivamento')}
                </Card.Body>
              </Card>
            </Col>
          </Row>
          {this.renderRow(private_demands, private_demands_filed, 'private', 'Demandas Privadas')}
          <Row>
            <Col className='col-3' >
              <Card className='card-style'>
                <Card.Body className='card-body-layout'>
                  {this.renderPieGraph(private_demands, private_demands_filed, 'status', 'private', 'Demandas privadas estado')}
                </Card.Body>
              </Card>
            </Col>
            <Col className='col-3' >
              <Card className='card-style'>
                <Card.Body className='card-body-layout'>
                  {this.renderPieGraph(private_demands, private_demands_filed, 'classification', 'private', 'Demandas privadas classificação')}
                </Card.Body>
              </Card>
            </Col>
            <Col className='col-3' >
              <Card className='card-style'>
                <Card.Body className='card-body-layout'>
                  {this.renderPieGraph([], private_demands_filed, 'filed_by_description', 'private', 'Demandas privadas arquivadas')}
                </Card.Body>
              </Card>
            </Col>
            <Col className='col-3' >
              <Card className='card-style'>
                <Card.Body className='card-body-layout'>
                  {this.renderPieGraph([], private_demands_filed, 'reason', 'private', 'Demandas privadas por razão de arquivamento')}
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    )
  }
}

const mapStateToProps = (state, props) => ({
  public_demands: selectorsDemands.getPublicDemands(state, props),
  public_demands_filed: selectorsDemands.getPublicDemandsFiled(state, props),
  private_demands: selectorsDemands.getPrivateDemands(state, props),
  private_demands_filed: selectorsDemands.getPrivateDemandsFiled(state, props),
});

const mapDispatchToProps = dispatch => ({
  getAllPublicDemands: () => dispatch(demandActions.getAllPublicDemands()),
  getAllPublicDemandsFiled: () => dispatch(demandActions.getAllPublicDemandsFiled()),
  getAllPrivateDemands: () => dispatch(demandActions.getAllPrivateDemands()),
  getAllPrivateDemandsFiled: () => dispatch(demandActions.getAllPrivateDemandsFiled()),
});

export default connect(mapStateToProps, mapDispatchToProps)(HomeContainer);