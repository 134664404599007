import { all, fork, call, put, takeEvery } from 'redux-saga/effects'
import { constants } from '../reducers/users'
import * as api from '../api/users'
import history from '../history'

function* getAll(action) {
  try {
    const response = yield call(api.getAll, action);
    yield put({
      type: constants.USER_GET_ALL.SUCCESS,
      users: response.data.result
    });
  } catch (e) {
    yield put({
      type: constants.USER_GET_ALL.FAILED,
      userErros: e.message || e
    });
  }
}
function* watchGetAll() {
  yield takeEvery(constants.USER_GET_ALL.ACTION, getAll);
}

function* create(action) {
  try {
    yield call(api.insertUser, action);
    yield put({
      type: constants.USER_CREATE.SUCCESS
    });
    const response = yield call(api.getAll, action);
    yield put({
      type: constants.USER_GET_ALL.SUCCESS,
      users: response.data.result
    });
    history.push('/admin');
  } catch (e) {
    yield put({
      type: constants.USER_CREATE.FAILED,
      userErros: e.message || e
    });
  }
}
function* watchCreate() {
  yield takeEvery(constants.USER_CREATE.ACTION, create);
}

function* update(action) {
  try {
    yield call(api.updatePassword, action);
    yield put({ type: constants.USER_UPDATE_PASS.SUCCESS });
  } catch (e) {
    yield put({
      type: constants.USER_UPDATE_PASS.FAILED,
      userErros: e.message || e
    });
    history.push('/admin')
  }
}
function* watchUpdate() {
  yield takeEvery(constants.USER_UPDATE_PASS.ACTION, update);
}

function* deleteUser(action) {
  try {
    yield call(api.deleteUser, action);
    yield put({ type: constants.USER_DELETE.SUCCESS });
    const response = yield call(api.getAll, action);
    yield put({
      type: constants.USER_GET_ALL.SUCCESS,
      users: response.data.result
    });
    history.push('/admin');
  } catch (e) {
    yield put({
      type: constants.USER_DELETE.FAILED,
      userErros: e.message || e
    });
  }
}
function* watchDelete() {
  yield takeEvery(constants.USER_DELETE.ACTION, deleteUser);
}
/**
 * Export the root saga by forking all available sagas.
 */
export function* rootSaga() {
  yield all([
    fork(watchGetAll),
    fork(watchCreate),
    fork(watchUpdate),
    fork(watchDelete)
  ]);
}
