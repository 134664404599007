import React from "react"
import { Container, Card, Row, Col, Table, Button, Form, Spinner,Alert } from 'react-bootstrap'
import { connect } from 'react-redux'
import { actions } from '../../reducers/users'
import * as selector from '../../selectors/users'
import _ from 'lodash'
import NavComponent from '../../components/NavComponent/NavComponent'

import './admin.style.css'
import {getUserId} from "../../services/auth";

class AdminContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userId: getUserId(),
      oldPassword: '',
      newPassword: '',
      newPasswordConfirmation: '',
      isLoading: false,
      name: '',
      email: '',
      password: 'gosc#123',
      showAlertError: false,
      erroMessage: '',
      showAlertSuccess: false,
      successMessage: '',
      passChange: false,
      passChangeFields: false,
    };

    this.onInputChange = this.onInputChange.bind(this);
    this.handleInsert = this.handleInsert.bind(this);
    this.handleUpdatePassword = this.handleUpdatePassword.bind(this);
    this.handleDismiss = this.handleDismiss.bind(this);
  }

  componentDidMount = () => {
    const { getAll } = this.props;
    getAll()
  };

  onInputChange(e) {
    const inputName = e.target.name;
    const inputValue = e.target.value;
    this.setState({ [inputName]: inputValue });
  };

  handleDelete = (id) => {
    const { deleteUser } = this.props;
    deleteUser(id)
  };


  showAlertSuccess = (message) => {
    this.setState({
      showAlertSuccess: true,
      successMessage: message,
    });

    setTimeout(()=> {
      this.setState({
        showAlertSuccess: false,
        successMessage: '',
      })
    }, 4000)
  };

  showAlertError = (message) => {
    this.setState({
      showAlertError: true,
      erroMessage: message,
    });

    setTimeout(()=> {
      this.setState({
        showAlertError: false,
        erroMessage: '',
      })
    }, 4000)
  };

  handleUpdatePassword = (e) => {
    e.preventDefault();
    const { updatePassword, error } = this.props;

    const {
      oldPassword,
      newPassword,
      newPasswordConfirmation,
      userId,
    } = this.state;

    this.setState({
      passChange: true,
      passChangeFields: true
    });

    if (oldPassword.trim() === '' && newPasswordConfirmation.trim() === '' && newPasswordConfirmation.trim() === '') {
      return this.showAlertError('Por favor preencha todos os campos')
    }

    if (newPassword !== newPasswordConfirmation) {
      return this.showAlertError('Novas senha e confirmação não conferem')
    }

    const user = {
      id: userId,
      oldPassword: oldPassword,
      newPassword: newPassword
    };
    updatePassword(user);

    setTimeout(() => {
      if (error !== null) {
        this.showAlertSuccess('Senha alterada com sucesso');
        this.setState({
          oldPassword: '',
          newPassword: '',
          newPasswordConfirmation: '',
          passChange: false,
          passChangeFields: false
        });
      } else {
        this.showAlertError('Senha atual inválida');
        this.setState({
          oldPassword: '',
          newPassword: '',
          newPasswordConfirmation: '',
          passChange: false,
          passChangeFields: false
        });
      }
    }, 3000);

  };

  handleInsert = (e) => {
    e.preventDefault();
    const {
      name,
      email,
      password
    } = this.state;

    const { createUser } = this.props;

    const user = {
      name: name,
      email: email,
      password: password
    };

    createUser(user);
    this.setState({name: '', email: ''})
  };

  handleDismiss = () => {
    this.setState({
      showAlertError: false,
      erroMessage: '',
    })
  };

  renderTableContent = () => {
    const { users } = this.props;

    return (
      _.map(users, (user, index)=>(
        <tr key={index}>
          <td className='text-center text-table'>{user.name}</td>
          <td className='text-center text-table'>{user.email}</td>
          <td className='text-center text-table'>{user.created}</td>
          <td className='text-center text-table'>{user.updated ? user.updated : '--'}</td>
          <td className='text-center text-table'>
            <Button
              onClick={()=> this.handleDelete(user.id)}
              variant="danger">
              Deletar
            </Button>
          </td>
        </tr>
      ))
    )
  };

  render = () => {
    const {
      oldPassword,
      newPassword,
      newPasswordConfirmation,
      name,
      email,
      showAlertError,
      erroMessage,
      passChange,
      showAlertSuccess,
      successMessage,
      passChangeFields
    } = this.state;

    return (
      <div>
        <NavComponent/>
        <Container fluid>
          <Alert
            className='alert'
            key={'error'}
            variant={'danger'}
            show={showAlertError}
            onClose={this.handleDismiss}>
            {erroMessage}
          </Alert>
          <Alert
            className='alert'
            key={'success'}
            variant={'success'}
            show={showAlertSuccess}
            onClose={this.handleDismiss}>
            {successMessage}
          </Alert>
          <Row className='root'>
            <Col className='col-12' >
              <Card className='card-style'>
                <Card.Body className='card-body-layout'>

                  <Form className='form'>
                    <Form.Row>
                      <Form.Group as={Col} controlId="oldPassword">
                        <Form.Label className='text-table'>Senha Antiga</Form.Label>
                        <Form.Control
                          disabled={passChangeFields}
                          type="password"
                          name={'oldPassword'}
                          value={oldPassword}
                          onChange={this.onInputChange}
                          placeholder="Digite sua senha atual" />
                      </Form.Group>
                      <Form.Group as={Col} controlId="newPassword">
                        <Form.Label className='text-table'>Nova senha</Form.Label>
                        <Form.Control
                          disabled={passChangeFields}
                          type="password"
                          name={'newPassword'}
                          value={newPassword}
                          onChange={this.onInputChange}
                          placeholder="Digite sua nova senha" />
                      </Form.Group>
                      <Form.Group as={Col} controlId="newPasswordConfirmation">
                        <Form.Label className='text-table'>Confimar nova senha</Form.Label>
                        <Form.Control
                          disabled={passChangeFields}
                          type="password"
                          name={'newPasswordConfirmation'}
                          value={newPasswordConfirmation}
                          onChange={this.onInputChange}
                          placeholder="Confirme a nova senha" />
                      </Form.Group>
                    </Form.Row>
                    {passChange ? (
                      <Button variant="success" disabled>
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                         <span className='button-margin'>Carregando</span>
                      </Button>
                    ) : (
                      <Button
                        onClick={this.handleUpdatePassword}
                        variant="success"
                        type="submit">
                        Trocar senha
                      </Button>
                    ) }
                  </Form>

                  <Form className='form'>
                    <Form.Row>
                      <Form.Group as={Col} controlId="nome">
                        <Form.Label className='text-table'>Nome</Form.Label>
                        <Form.Control
                          type="nome"
                          name={'name'}
                          value={name}
                          onChange={this.onInputChange}
                          placeholder="Preencha o nome do novo admin" />
                      </Form.Group>
                      <Form.Group as={Col} controlId="email">
                        <Form.Label className='text-table'>Email</Form.Label>
                        <Form.Control
                          type="email"
                          name={'email'}
                          value={email}
                          onChange={this.onInputChange}
                          placeholder="Preencha o email" />
                      </Form.Group>
                    </Form.Row>
                    <Button
                      onClick={this.handleInsert}
                      variant="success"
                      type="submit">
                      Inserir
                    </Button>
                  </Form>

                  <Table striped bordered hover size="sm">
                    <thead>
                    <tr>
                      <th className='text-center text-table text-table-header'>Nome</th>
                      <th className='text-center text-table text-table-header'>Email</th>
                      <th className='text-center text-table text-table-header'>Criado em</th>
                      <th className='text-center text-table text-table-header'>Atualizado em</th>
                      <th className='text-center text-table text-table-header'>Açoes</th>
                    </tr>
                    </thead>
                    <tbody>
                    {this.renderTableContent()}
                    </tbody>
                  </Table>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    )
  }
}

const mapStateToProps = (state, props) => ({
  users: selector.users(state, props),
  error: selector.errors(state, props)
});

const mapDispatchToProps = dispatch => ({
  getAll: () => dispatch(actions.getAll()),
  deleteUser: (id) => dispatch(actions.deleteUser(id)),
  createUser: (user) => dispatch(actions.createUser(user)),
  updatePassword: (user) => dispatch(actions.updateUser({user})),
});

export default connect(mapStateToProps, mapDispatchToProps)(AdminContainer);