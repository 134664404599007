import React, {Component} from "react"
import { Navbar, Button } from "react-bootstrap"
import { Link, withRouter } from 'react-router-dom'
import { FaBars, FaSignOutAlt, FaTimes } from 'react-icons/fa'
import { isAuthenticated, logout } from '../../services/auth'

import './NavComponent.style.css'

const logo = require('../../assets/images/logo_gosc.png');

class NavComponent extends Component{
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false
    };
    this.closeNav = this.closeNav.bind(this);
    this.openNav = this.openNav.bind(this);
    this.renderSideMenu = this.renderSideMenu.bind(this);
    this.logout = this.logout.bind(this)
  }

  logout = () => {
    logout();
    this.props.history.push('/login')
  };

  openNav = () => {
    this.setState({isOpen: true});
    document.getElementById("mySidenav").style.width = "340px";
  };

  /* Set the width of the side navigation to 0 */
  closeNav = () => {
    this.setState({isOpen: false});
    document.getElementById("mySidenav").style.width = "0";
  };

  renderSideMenu = () => {
    const { isOpen } = this.state;
    if (isAuthenticated()) {
      return (
        <div>
          <div
            id="mySidenav"
            className="sidenav">
            <Link to="/">Início</Link>
            <Link to="/admin">Cadastrar Admin/Mudança de senha</Link>
            <Link to="/demands/public">Listar Demandas públicas</Link>
            <Link to="/demands/private">Listar Demandas privadas</Link>
          </div>
          {isOpen ? (
            <a className='bars-menu' onClick={this.closeNav}>
              <FaTimes color={'#FFFFFF'} size={'1.5em'} />
            </a>
          ) : (
            <a className='bars-menu' onClick={this.openNav}>
              <FaBars color={'#FFFFFF'} size={'1.5em'} />
            </a>
          )}
          <Button className='out-menu' variant='light' onClick={this.logout}>
            <FaSignOutAlt color={'#FFFFFF'} size={'1.5em'} /> <span className='out-text'>Sair</span>
          </Button>
        </div>
      )
    }
  };

  render = () => {
    return (
      <Navbar className='nav-center'>
        {this.renderSideMenu()}
        <Navbar.Brand>
          <img
            alt="logo_gosc"
            src={logo}
            width="30"
            height="30"
            className="d-inline-block align-top"
          />
          <span className='span-style'>GOSC Admin</span>
        </Navbar.Brand>
      </Navbar>
    )
  }
}

export default withRouter(NavComponent)