import api from '../shared/api'

export function getAllPublicDemands() {
  return api('/demand/getAllPublicDemands', {
    method: 'GET'
  });
}

export function getAllPrivateDemands() {
  return api('/demand/getAllPrivateDemands', {
    method: 'GET'
  });
}

export function getAllFiledPublicDemands() {
  return api('/demand/getAllFiledPublicDemands', {
    method: 'GET'
  });
}

export function getAllFiledPrivateDemands() {
  return api('/demand/getAllFiledPrivateDemands', {
    method: 'GET'
  });
}

export function getDemandsClassificationCount({demand_type}) {
  return api(`/Demand/classification/${demand_type}`, {
    method: 'GET'
  });
}

export function getDemandsFiledTypeCount({demand_type}) {
  return api(`/Demand/filed/${demand_type}`, {
    method: 'GET'
  });
}

export function getDemandStatusCount({demand_type}) {
  return api(`/Demand/status/${demand_type}`, {
    method: 'GET'
  });
}

export function fileDemand({filed}) {
  return api(`/Demand/fileDemand`, {
    method: 'PUT',
    data: {
      id: filed.id,
      text: filed.text,
      filed_by: 3
    }
  });
}