import { all, fork } from 'redux-saga/effects';
import { rootSaga as demands } from './demands';
import { rootSaga as users } from './users';

function* rootSaga() {
  const sagas = [
    demands,
    users
  ].map(fork);
  yield all(sagas);
}

export default rootSaga;
