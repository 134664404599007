import { createSagaAction } from '../shared/sagas';
import { createReducer } from '../shared/reducers';

// Constants
export const constants = {
  PUBLIC_DEMANDS: createSagaAction('PUBLIC_DEMANDS'),
  PUBLIC_DEMANDS_FILED: createSagaAction('PUBLIC_DEMANDS_FILED'),
  PRIVATE_DEMANDS: createSagaAction('PRIVATE_DEMANDS'),
  PRIVATE_DEMANDS_FILED: createSagaAction('PRIVATE_DEMANDS_FILED'),

  FILE_PUBLIC_DEMAND: createSagaAction('FILE_DEMAND'),
  FILE_PRIVATE_DEMAND: createSagaAction('FILE_DEMAND')
};

// Action Creators
export const actions = {
  getAllPublicDemands: () => ({
    type: constants.PUBLIC_DEMANDS.ACTION,
  }),
  getAllPublicDemandsFiled: () => ({
    type: constants.PUBLIC_DEMANDS_FILED.ACTION,
  }),
  getAllPrivateDemands: () => ({
    type: constants.PRIVATE_DEMANDS.ACTION,
  }),
  getAllPrivateDemandsFiled: () => ({
    type: constants.PRIVATE_DEMANDS_FILED.ACTION,
  }),
  filePublicDemand: (filed) => ({
    type: constants.FILE_PUBLIC_DEMAND.ACTION,
    filed
  }),
  filePrivateDemand: (filed) => ({
    type: constants.FILE_PRIVATE_DEMAND.ACTION,
    filed
  })
};

// Reducer
export const initialState = {
  error: null,
  public_demands: [],
  public_demands_filed: [],
  private_demands: [],
  private_demands_filed: []
};

const ACTION_HANDLERS = {
  [constants.PUBLIC_DEMANDS.ACTION]: state => {
    return { ...state };
  },
  [constants.PUBLIC_DEMANDS.SUCCESS]: (state, { public_demands }) => {
    return { ...state, public_demands };
  },
  [constants.PUBLIC_DEMANDS.FAILED]: (state, { error }) => {
    return { ...state, error };
  },

  [constants.PUBLIC_DEMANDS_FILED.ACTION]: state => {
    return { ...state };
  },
  [constants.PUBLIC_DEMANDS_FILED.SUCCESS]: (state, { public_demands_filed }) => {
    return { ...state, public_demands_filed };
  },
  [constants.PUBLIC_DEMANDS_FILED.FAILED]: (state, { error }) => {
    return { ...state, error };
  },

  [constants.PRIVATE_DEMANDS.ACTION]: state => {
    return { ...state };
  },
  [constants.PRIVATE_DEMANDS.SUCCESS]: (state, { private_demands }) => {
    return { ...state, private_demands };
  },
  [constants.PRIVATE_DEMANDS.FAILED]: (state, { error }) => {
    return { ...state, error };
  },

  [constants.PRIVATE_DEMANDS_FILED.ACTION]: state => {
    return { ...state };
  },
  [constants.PRIVATE_DEMANDS_FILED.SUCCESS]: (state, { private_demands_filed }) => {
    return { ...state, private_demands_filed };
  },
  [constants.PRIVATE_DEMANDS_FILED.FAILED]: (state, { error }) => {
    return { ...state, error };
  },

  [constants.FILE_PUBLIC_DEMAND.FAILED]: (state, { error }) => {
    return { ...state, error };
  },
  [constants.FILE_PRIVATE_DEMAND.FAILED]: (state, { error }) => {
    return { ...state, error };
  }
};

export default createReducer(initialState, (state, action) => {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : { ...state };
});
