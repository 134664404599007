import api from '../shared/api'

export function login(email, password) {
  return api(`/token`, {
    method: 'POST',
    data: {
      email: email,
      password: password
    }
  });
}