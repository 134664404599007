import React, {Fragment} from 'react'
import { Modal, Button, Row } from 'react-bootstrap'
import moment from 'moment'
import {classificationTypeToString} from '../../shared/utils'
import './modal.style.css'

export default class ModalDemandDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      reason: ''
    };
    this.onInputChange = this.onInputChange.bind(this)
  }

  onInputChange(e) {
    const inputName = e.target.name;
    const inputValue = e.target.value;
    this.setState({ [inputName]: inputValue });
  };

  render() {
    const { showModal, onHide, demand } = this.props;
    return (
      <Modal
        show={showModal}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <Modal.Title
            className='modal-text'
            id="contained-modal-title-vcenter">
            {demand !== null ?  (
              demand.title
            ) : (
              ''
            )}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {demand !== null ? (
            <div>
              <Row className='row-style'>
                <div>
                  <p className='modal-text'>Crianda em:</p>
                  <p>{moment(new Date(demand.created_at)).format("DD/MM/YYYY")}</p>
                </div>
                <div>
                  <p className='modal-text'>CIM do demandante</p>
                  <p>{demand.creator_id}</p>
                </div>
                <div>
                  <p className='modal-text'>Demandante:</p>
                  <p>{demand.creator_name}</p>
                </div>
                <div>
                  <p className='modal-text'>Status:</p>
                  <p>{demand.status}</p>
                </div>
                <div>
                  <p className='modal-text'>Classificação:</p>
                  <p>{classificationTypeToString(demand.classification)}</p>
                </div>
              </Row>
              {demand.status === 'Arquivada' ? (
                <Fragment>
                  <Row className='row-style'>
                    <div>
                      <p className='modal-text'>Arquivada em:</p>
                      <p>{moment(new Date(demand.filed_at)).format("DD/MM/YYYY")}</p>
                    </div>
                    <div>
                      <p className='modal-text'>Arquivada por:</p>
                      <p>{demand.filed_by_description}</p>
                    </div>
                    <div>
                      <p className='modal-text'>Tipo:</p>
                      <p>{demand.reason}</p>
                    </div>
                  </Row>
                  <Row className='row-style'>
                    <div>
                      <p className='modal-text'>Motivo arquivamento:</p>
                      <p>{demand.archiving_reason.trim() !== '' ? demand.archiving_reason : '--'}</p>
                    </div>
                  </Row>
                </Fragment>
              ) : undefined}
              <Row className='row-style'>
                <div>
                  <p className='modal-text'>Descrição:</p>
                  <p>{demand.description}</p>
                </div>
              </Row>
            </div>
          ) : undefined}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={()=> onHide()}>Fechar</Button>
        </Modal.Footer>
      </Modal>
    );
  }
}
