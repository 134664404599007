import { all, fork, call, put, takeEvery } from 'redux-saga/effects'
import { constants } from '../reducers/demands'
import * as api from '../api/demands'
import history from "../history";

function* getAllPublicDemands(action) {
  try {
    const response = yield call(api.getAllPublicDemands, action);
    yield put({
      type: constants.PUBLIC_DEMANDS.SUCCESS,
      public_demands: response.data.result
    });
  } catch (e) {
    yield put({
      type: constants.PUBLIC_DEMANDS.FAILED,
      error: e.message || e
    });
  }
}
function* watchGetAllPublicDemands() {
  yield takeEvery(constants.PUBLIC_DEMANDS.ACTION, getAllPublicDemands);
}

function* getAllPublicDemandsFiled(action) {
  try {
    const response = yield call(api.getAllFiledPublicDemands, action);
    yield put({
      type: constants.PUBLIC_DEMANDS_FILED.SUCCESS,
      public_demands_filed: response.data.result
    });
  } catch (e) {
    yield put({
      type: constants.PUBLIC_DEMANDS_FILED.FAILED,
      error: e.message || e
    });
  }
}
function* watchGetAllPublicDemandsFiled() {
  yield takeEvery(constants.PUBLIC_DEMANDS_FILED.ACTION, getAllPublicDemandsFiled);
}

function* getAllPrivateDemands(action) {
  try {
    const response = yield call(api.getAllPrivateDemands, action);
    yield put({
      type: constants.PRIVATE_DEMANDS.SUCCESS,
      private_demands: response.data.result
    });
  } catch (e) {
    yield put({
      type: constants.PRIVATE_DEMANDS.FAILED,
      error: e.message || e
    });
  }
}
function* watchGetAllPrivateDemands() {
  yield takeEvery(constants.PRIVATE_DEMANDS.ACTION, getAllPrivateDemands);
}

function* getAllPrivateDemandsFiled(action) {
  try {
    const response = yield call(api.getAllFiledPrivateDemands, action);
    yield put({
      type: constants.PRIVATE_DEMANDS_FILED.SUCCESS,
      private_demands_filed: response.data.result
    });
  } catch (e) {
    yield put({
      type: constants.PRIVATE_DEMANDS_FILED.FAILED,
      error: e.message || e
    });
  }
}
function* watchGetAllPrivateDemandsFiled() {
  yield takeEvery(constants.PRIVATE_DEMANDS_FILED.ACTION, getAllPrivateDemandsFiled);
}

function* filePublicDemand(action) {
  try {
    yield call(api.fileDemand, action);
    yield put({
      type: constants.FILE_PUBLIC_DEMAND.SUCCESS,
    });
    const response = yield call(api.getAllPublicDemands, action);
    yield put({
      type: constants.PUBLIC_DEMANDS.SUCCESS,
      public_demands: response.data.result
    });
    const response_filed = yield call(api.getAllFiledPublicDemands, action);
    yield put({
      type: constants.PUBLIC_DEMANDS_FILED.SUCCESS,
      public_demands_filed: response_filed.data.result
    });
    // history.push('/demands/public');
  } catch (e) {
    yield put({
      type: constants.FILE_PUBLIC_DEMAND.FAILED,
      error: e.message || e
    });
  }
}
function* watchFilePublicDemand() {
  yield takeEvery(constants.FILE_PUBLIC_DEMAND.ACTION, filePublicDemand);
}

function* filePrivateDemand(action) {
  try {
    yield call(api.fileDemand, action);
    yield put({
      type: constants.FILE_PRIVATE_DEMAND.SUCCESS,
    });
    const response = yield call(api.getAllPrivateDemands, action);
    yield put({
      type: constants.PRIVATE_DEMANDS.SUCCESS,
      private_demands: response.data.result
    });
    const response_filed = yield call(api.getAllFiledPrivateDemands, action);
    yield put({
      type: constants.PRIVATE_DEMANDS_FILED.SUCCESS,
      private_demands_filed: response_filed.data.result
    });
    // history.push('/demands/private');
  } catch (e) {
    yield put({
      type: constants.FILE_PRIVATE_DEMAND.FAILED,
      error: e.message || e
    });
  }
}
function* watchFilePrivateDemand() {
  yield takeEvery(constants.FILE_PRIVATE_DEMAND.ACTION, filePrivateDemand);
}

/**
 * Export the root saga by forking all available sagas.
 */
export function* rootSaga() {
  yield all([
    fork(watchGetAllPublicDemands),
    fork(watchGetAllPublicDemandsFiled),
    fork(watchGetAllPrivateDemands),
    fork(watchGetAllPrivateDemandsFiled),
    fork(watchFilePublicDemand),
    fork(watchFilePrivateDemand),
  ]);
}
