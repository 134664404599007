import api from '../shared/api'

export function insertUser({user}) {
  return api(`/User/insert`, {
    method: 'POST',
    data: {
      email: user.email,
      password: user.password,
      name: user.name
    }
  });
}

export function deleteUser({id}) {
  return api(`/User/delete/${id}`, {
    method: 'DELETE'
  });
}

export function updatePassword({user}) {
  return api('/User/update', {
    method: 'PUT',
    data: {
      id: user.user.id,
      oldPassword: user.user.oldPassword,
      newPassword: user.user.newPassword
    }
  })
}

export function getAll() {
  return api(`/User/getAll`, {
    method: 'GET'
  });
}