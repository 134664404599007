import { createSagaAction } from '../shared/sagas';
import { createReducer } from '../shared/reducers';

// Constants
export const constants = {
  USER_GET_ALL: createSagaAction('USER_GET_ALL'),
  USER_CREATE: createSagaAction('USER_CREATE'),
  USER_UPDATE_PASS: createSagaAction('USER_UPDATE'),
  USER_DELETE: createSagaAction('USER_DELETE'),
};

// Action Creators
export const actions = {
  getAll: () => ({
    type: constants.USER_GET_ALL.ACTION,
  }),
  createUser: (user) => ({
    type: constants.USER_CREATE.ACTION,
    user
  }),
  updateUser: ( user ) => {
    return {
      type: constants.USER_UPDATE_PASS.ACTION,
      user
    };
  },
  deleteUser: id => ({
    type: constants.USER_DELETE.ACTION,
    id
  }),
};

// Reducer
export const initialState = {
  userErros: null,
  users: [],
};

const ACTION_HANDLERS = {
  [constants.USER_GET_ALL.ACTION]: state => {
    return { ...state };
  },
  [constants.USER_GET_ALL.SUCCESS]: (state, { users }) => {
    return { ...state, users };
  },
  [constants.USER_GET_ALL.FAILED]: (state, { userErros }) => {
    return { ...state, userErros: userErros };
  },
  [constants.USER_CREATE.FAILED]: (state, { userErros }) => {
    return { ...state, userErros: userErros };
  },
  [constants.USER_UPDATE_PASS.FAILED]: (state, { userErros }) => {
    return { ...state, userErros: userErros };
  },
  [constants.USER_DELETE.FAILED]: (state, { userErros }) => {
    return { ...state, userErros: userErros };
  }
};

export default createReducer(initialState, (state, action) => {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : { ...state };
});
