import React, {Component} from "react"
import { Pie } from 'react-chartjs-2'
import { Spinner } from 'react-bootstrap'
import './PieGraphComponent.style.css'

const privateColors = [
  '#CFE2F3',
  '#70A8DB',
  '#3D85C6',
  '#3e66a8',
  '#3f4f92',
  '#3f3f80',
  '#36366d',
  '#2b2b56',
  '#212143',
  '#16162d',
];

const publicColors = [
  '#D8D1E9',
  '#8E7DC3',
  '#684EA7',
  '#50488f',
  '#393973',
  '#2b2b57',
  '#232351',
  '#302643',
  '#261c39',
];

export default class PieGraphComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      colorSet: [],
      isLoading: true
    };
  }

  componentDidMount = () => {
    setTimeout(() => {
      this.setState({isLoading: false})
    }, 10000)
  };

  render = () => {
    const {isLoading} = this.state;
    const {
      labels,
      dataSet,
      colors,
      title
    } = this.props;

    if (dataSet.length === 0) {
      return  (
        <div className='spiner-view'>
          {isLoading ? (
            <Spinner animation="border" variant="dark" />
          ) : (
            <p className='demand-not-found'>Ainda não existem demandas cadastradas</p>
          )}
        </div>
      )
    }

    const colorSet = colors === 'public' ? publicColors : privateColors;

    const data = {
      labels: labels,
      datasets: [{
        data: dataSet,
        backgroundColor: colorSet,
        hoverBackgroundColor: colorSet
      }]
    };

    const options={
      legend: {
        display: false,
      },
    };

    return (
      <div>
        <p className='text-card text-center'>{title}</p>
        <Pie
          data={data}
          options={options}
        />
      </div>
    )
  }
}