export function classificationTypeToString(classification) {
  switch(classification){
    case 'masonic':
      return 'De ordem maçônica';
    case 'professional':
      return 'De ordem profissional ou associada ao trabalho';
    case 'services':
      return 'Demanda por serviços ou produtos em geral';
    case 'health':
      return 'De ordem médica ou associada à saúde';
    case 'juridic':
      return 'De ordem jurídica ou associada à justiça';
    case 'personal':
      return 'De ordem pessoal ou familiar';
    default:
      return classification;
  }
}