import React from "react";
import {Router, Route, Switch, Redirect} from "react-router-dom";
import { isAuthenticated } from "./services/auth";
import LoginContainer from './containers/login/LoginContainer'
import HomeContainer from './containers/home/HomeContainer'
import AdminContainer from './containers/admin/AdminContainer'
import ListPrivateDemandsContainer from './containers/listDemands/ListPrivateDemandsContainer'
import ListPublicDemandsContainer from './containers/listDemands/ListPublicDemandsContainer'
import history from './history';

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      isAuthenticated() ? (
        <Component {...props} />
      ) : (
        <Redirect to={{ pathname: "/login", state: { from: props.location } }} />
      )
    }
  />
);

const Routes = () => (
    <Router history={history} >
      <Switch>
        <Route exact path={'/login'} component={LoginContainer} />
        <PrivateRoute exact path='/' component={HomeContainer} />
        <PrivateRoute exact path={'/home'} component={HomeContainer} />
        <PrivateRoute exact path={'/admin'} component={AdminContainer} />
        <PrivateRoute exact path={'/demands/public'} component={ListPublicDemandsContainer} />
        <PrivateRoute exact path={'/demands/private'} component={ListPrivateDemandsContainer} />
      </Switch>
    </Router>
);

export default Routes;