import React, {Fragment} from "react"
import { Container, Card, Row, Col, Table, Button, Pagination, Form } from 'react-bootstrap'
import { connect } from 'react-redux'
import _ from 'lodash'
import moment from 'moment'
import NavComponent from '../../components/NavComponent/NavComponent'
import MainRow from "../../components/MainRow/MainRow"
import ModalComponent from "../../components/ModalComponent/ModalComponent"
import ModalDemandDetail from "../../components/ModalComponent/ModalDemandDetail"
import * as selectorsDemands from "../../selectors/demands"
import { actions as demandActions } from "../../reducers/demands"
import { classificationTypeToString } from '../../shared/utils'
import InputMask from 'react-input-mask';

import './listDemands.style.css'

class ListPrivateDemandsContainer extends React.Component{
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      showModalDetail: false,
      demandToFile: null,
      demandToShow: null,
      activePage: 1,
      startDate: '',
      finalDate: '',
      demandState: 'Todas'
    };

    this.hideModal = this.hideModal.bind(this);
    this.hideModalDetail = this.hideModalDetail.bind(this);
    this.fileDemand = this.fileDemand.bind(this);
    this.fileDemandModal = this.fileDemandModal.bind(this);
    this.renderPagination = this.renderPagination.bind(this);
    this.onInputChange = this.onInputChange.bind(this);
  }

  componentDidMount = () => {
    const {
      getAllPrivateDemands,
      getAllPrivateDemandsFiled
    } = this.props;

    getAllPrivateDemands();
    getAllPrivateDemandsFiled();
  };

  onInputChange(e) {
    const inputName = e.target.name;
    const inputValue = e.target.value;
    this.setState({ [inputName]: inputValue });
  };

  renderPagination = () => {
    const {
      private_demands,
      private_demands_filed
    } = this.props;

    const { activePage } = this.state;

    const demands = _.union(private_demands, private_demands_filed);

    let pages = Math.ceil((demands.length/20));

    let active = activePage;
    let items = [];
    for (let number = 1; number <= pages; number++) {
      items.push(
        <Pagination.Item
          key={number}
          active={number === active}
          onClick={() => {
            this.setState({activePage: number})
          }}
        >
          {number}
        </Pagination.Item>,
      );
    }

    let toShow = this.renderNumbersPagination(items);

    return (
      <div>
        <Pagination>
          {activePage !== 1 ? (
            <Fragment>
              <Pagination.First
                onClick={() => {
                  this.setState({activePage: 1})
                }}
              />
              <Pagination.Prev
                onClick={() => {
                  let next = activePage - 1;
                  this.setState({activePage: next})
                }}
              />
            </Fragment>
          ) : undefined }
          {toShow}
          {activePage !== pages ? (
            <Fragment>
              <Pagination.Next
                onClick={() => {
                  let next = activePage + 1;
                  this.setState({activePage: next})
                }}
              />
              <Pagination.Last
                onClick={() => {
                  this.setState({activePage: pages})
                }}
              />
            </Fragment>
          ) : undefined }
        </Pagination>
      </div>
    );
  };

  demandsToShow(demands) {
    const { activePage } = this.state;

    if (activePage === 1) {
      let finish = (activePage * 20) - 1;
      let start = activePage - 1;
      return demands.slice(start, finish)
    } else {
      let finish  = (activePage * 20) - 1;
      let start = (activePage * 20) - 20;
      return demands.slice(start, finish)
    }
  }

  renderFilter = () => {
    const {
      startDate,
      finalDate,
    } = this.state;

    return (
      <div className='form-filter'>
        <Form>
          <Form.Row className='layout-form'>
            <Form.Group as={Col} md={{ span: 1, offset: 9 }} controlId="formGridState">
              <Form.Label className='lable-text'>Estado da demanda</Form.Label>
              <Form.Control
                as="select"
                onChange={ e => {
                  this.setState({
                    demandState: e.target.value
                  })
                }}
              >
                <option value='#'>Todas</option>
                <option value='Abertas'>Abertas</option>
                <option value='Arquivadas'>Arquivadas</option>
                <option value='Aceitas'>Aceitas</option>
              </Form.Control>
            </Form.Group>
            <Form.Group as={Col} controlId="formGridStart" className='col-1'>
              <Form.Label className='lable-text'>Data inicial</Form.Label>
              <InputMask
                className='lable-text'
                mask="99/99/9999"
                value={startDate}
                onChange={this.onInputChange}
                name={'startDate'}>
                {(inputProps) => <Form.Control {...inputProps} type="text" placeholder="Ex. 11/11/1111" />}
              </InputMask>
            </Form.Group>
            <Form.Group as={Col} controlId="formGridFinal" className='col-1'>
              <Form.Label className='lable-text'>Data final</Form.Label>
              <InputMask
                className='lable-text'
                mask="99/99/9999"
                value={finalDate}
                onChange={this.onInputChange}
                name={'finalDate'}>
                {(inputProps) => <Form.Control {...inputProps} type="text" placeholder="Ex. 11/11/1111" />}
              </InputMask>
            </Form.Group>
          </Form.Row>
        </Form>
      </div>
    )
  };

  renderNumbersPagination = (items) => {
    const { activePage } = this.state;
    if (activePage - 5 > 0) {
      let finish = activePage + 2;
      let start = activePage - 3;
      return items.slice(start, finish)
    } else {
      return items.slice(0, 5)
    }
  };

  filterTable = (demands) => {
    const {
      startDate,
      finalDate,
      demandState
    } = this.state;

    const demandsByState = _.filter(demands, demand => {
      if (demandState === 'Arquivadas') {
        if (demand.status === 'Arquivada') return demand;
      } else if (demandState === 'Aceitas') {
        if (demand.status === 'Aceita') return demand
      } else if (demandState === 'Abertas') {
        if (demand.status === 'Aberta') return demand
      } else {
        return demand
      }
    });

    return _.filter(demandsByState, demand => {
      if ((startDate === '__/__/____' || startDate === '') && (finalDate === '__/__/____' || finalDate === '')) {
        return demand

      } else if ((startDate !== '__/__/____' || startDate !== '') && (finalDate === '__/__/____' || finalDate === '')) {
        if (moment(new Date(demand.created_at)).format('DD-MM-YYYY') >= moment(startDate, '"DD-MM-YYYY"').format('DD-MM-YYYY'))
          return demand

      } else if ((startDate === '__/__/____' || startDate === '') && (finalDate !== '__/__/____' || finalDate !== '')) {
        if (moment(new Date(demand.created_at)).format('DD-MM-YYYY') <= moment(finalDate, '"DD-MM-YYYY"').format('DD-MM-YYYY'))
          return demand

      } else if ((startDate !== '__/__/____' || startDate !== '') && (finalDate !== '__/__/____' || finalDate !== '')) {
        if (
          (moment(new Date(demand.created_at)).format('DD-MM-YYYY') >= moment(startDate, '"DD-MM-YYYY"').format('DD-MM-YYYY')) &&
          (moment(new Date(demand.created_at)).format('DD-MM-YYYY') <= moment(finalDate, '"DD-MM-YYYY"').format('DD-MM-YYYY'))
        )
        return demand
      }
    });
  };

  renderTableContent = () => {
    const {
      private_demands,
      private_demands_filed
    }= this.props;

    const demands = _.union(private_demands, private_demands_filed);

    let filterDemands = this.filterTable(demands);

    let toShow = this.demandsToShow(filterDemands);

    return (
      _.map(toShow, (demand, index)=>(
        <tr
          key={index}>
          <td className='text-center text-table'>{demand.title}</td>
          <td className='text-center text-table'>{demand.creator_name}</td>
          <td className='text-center text-table'>{classificationTypeToString(demand.classification)}</td>
          <td className='text-center text-table'>{moment(new Date(demand.created_at)).format("DD/MM/YYYY")}</td>
          <td className='text-center text-table'>{demand.status}</td>
          <td className='text-center text-table'>
            <Button
              className='button-action'
              onClick={()=> {this.detailModal(demand)}}
              variant="success">
              Ver detalhes
            </Button>
            {demand.status !== 'Arquivada' ? (
              <Button
                onClick={()=> {this.fileDemandModal(demand)}}
                variant="danger">
                Arquivar
              </Button>
            ) : undefined}
          </td>
        </tr>
      ))
    )
  };

  renderRow = () => {
    const {
      private_demands,
      private_demands_filed
    }= this.props;

    const demands = private_demands;
    const filed = private_demands_filed;
    const color = 'private';
    const title = 'Demandas Privadas';

    const status =
      _.chain(demands)
        .groupBy('status')
        .map((items, status) => {
          return { status: status, count: items.length };
        })
        .value();

    const open =
      _.chain(status)
        .find(o => {
          return o.status === 'Aberta'
        })
        .get('count')
        .value();

    const accept =
      _.chain(status)
        .find(o => {
          return o.status === 'Aceita'
        })
        .get('count')
        .value();

    const total = demands.length + filed.length;

    return (
      <MainRow
        color={color}
        title={title}
        numDemandas={total}
        numAbertas={open}
        numAceitas={accept}
        numArquivadas={filed.length}
      />
    )
  };

  hideModal = () => {
    this.setState({showModal: false})
  };

  hideModalDetail = () => {
    this.setState({showModalDetail: false})
  };

  fileDemand = (demand, reason) => {
    this.setState({showModal: false});
    const { filePrivateDemand } = this.props;

    const filed = {
      id: demand.id,
      text: reason
    };

    filePrivateDemand(filed)
  };

  fileDemandModal = (demand) => {
    this.setState({
      showModal: true,
      demandToFile: demand
    })
  };

  detailModal = (demand) => {
    this.setState({
      showModalDetail: true,
      demandToShow: demand
    })
  };

  render = () => {
    const {
      showModal,
      demandToFile,
      demandToShow,
      showModalDetail
    } = this.state;

    return (
      <div>
        <NavComponent />
        <Container fluid>
          {this.renderRow()}
          <Row className='root'>
            <Col className='col-12' >
              <Card className='card-style'>
                <Card.Body className='card-body-layout'>
                  {this.renderFilter()}
                  <Table striped bordered hover size="sm">
                    <thead>
                    <tr>
                      <th className='text-center text-table'>Título</th>
                      <th className='text-center text-table'>Demandante</th>
                      <th className='text-center text-table'>Classificação</th>
                      <th className='text-center text-table'>Data Registro</th>
                      <th className='text-center text-table'>Estado</th>
                      <th className='text-center text-table'>Ação</th>
                    </tr>
                    </thead>
                    <tbody>
                    {this.renderTableContent()}
                    </tbody>
                  </Table>
                  <div className='pagination'>
                    {this.renderPagination()}
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
        <ModalComponent
          onHide={this.hideModal}
          demand={demandToFile}
          onFile={this.fileDemand}
          showModal={showModal}
        />
        <ModalDemandDetail
          onHide={this.hideModalDetail}
          demand={demandToShow}
          showModal={showModalDetail}
        />
      </div>
    )
  }
}

const mapStateToProps = (state, props) => ({
  private_demands: selectorsDemands.getPrivateDemands(state, props),
  private_demands_filed: selectorsDemands.getPrivateDemandsFiled(state, props),
});

const mapDispatchToProps = dispatch => ({
  getAllPrivateDemands: () => dispatch(demandActions.getAllPrivateDemands()),
  getAllPrivateDemandsFiled: () => dispatch(demandActions.getAllPrivateDemandsFiled()),
  filePrivateDemand: filed => dispatch(demandActions.filePrivateDemand(filed)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ListPrivateDemandsContainer);