import React from 'react'
import { Modal, Button, Form } from 'react-bootstrap'
import './modal.style.css'

export default class ModalComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      reason: ''
    };
    this.onInputChange = this.onInputChange.bind(this)
  }

  onInputChange(e) {
    const inputName = e.target.name;
    const inputValue = e.target.value;
    this.setState({ [inputName]: inputValue });
  };

  render() {
    const { showModal, onHide, onFile, demand } = this.props;
    const { reason } = this.state;
    return (
      <Modal
        show={showModal}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <Modal.Title
            className='modal-text'
            id="contained-modal-title-vcenter">
            {demand !== null ?  (
              demand.title
            ) : (
              ''
            )}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="exampleForm.ControlTextarea1">
              <Form.Label className='modal-text'>Motivo do arquivamento</Form.Label>
              <Form.Control
                name={'reason'}
                value={reason}
                onChange={this.onInputChange}
                as="textarea"
                rows="4" />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={()=> onHide()}>Fechar</Button>
          <Button variant="success" onClick={() => {
            this.setState({reason: ''})
            onFile(demand, reason)
          }}>Arquivar</Button>
        </Modal.Footer>
      </Modal>
    );
  }
}
